<template>
  <div class="px-2">
    <div class="col-12 align-items-center mb-5">
      <div class="row align-items-center justify-content-between">
        <!-- <span class="text-capitalize">{{ $t("profile.subtitle") }}</span> -->
        <div class="h1 col-auto">{{ $t("profile.title") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Profile",
  };
</script>
